<template>
  <Modal modal-class="modal-confirm slide-up" size="md" v-on="$listeners" v-bind="$attrs" ref="modal">
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
    <template v-slot:footer>
      <div style="width: 100%; padding: 10px 14px" class="row justify-content-between">
        <div>
          <button @click="confirmHandle" type="button" :class="['btn btn-yes inline', confirmClass]">
            {{ $t('common.confirm') }}
          </button>
        </div>
        <div>
          <button type="button"
                  :class="['btn btn-default no-margin inline btn-no', cancelClass]"
                  data-dismiss="modal">
            {{ $t('common.no') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>

export default {
  props: {
    confirmHandle: Promise | Function,
    confirmClass: String,
    cancelClass: String,
  },
  methods: {
    hide() {
      this.$refs.modal.hide();
    },
    show() {
      this.$refs.modal.show();
    }
  }
}
</script>
