import breadcrumbs from "./breadcrumbs";

export default {
  "common": {
    "password": "パスワード",
    "password confirm": "パスワード再入力",
    "email": "Eメール",
    "mail address": "メールアドレス",
    "language": "言語",
    "next": "次へ",
    "pre": "前へ",
    "name": "名前",
    "role": "ロール",
    "action": "編集",
    "status": "ステータス",
    "active": "有効",
    "deactive": "無効",
    "total": "合計",
    "create": "追加",
    "reset": "検索条件をリセット",
    "perpage": "表示件数",
    "cancel": "キャンセル",
    "confirm": "はい",
    "no": "いいえ",
    "search": "検索",
    "phone": "電話番号",
    "creator": "作成者",
    "created at": "作成日",
    "updater": "担当者",
    "updated at": "更新日",
    "address": "住所",
    "fax": "FAX",
    "save": "保存",
    "send": "送信",
    "error": "エラーが発生しました。再度実行してください。",
    "new_password": "新しいパスワード",
    "new_password_confirm": "新しいパスワード（確認）",
    "current_password": "現在のパスワード",
    "current_email": "現在のメールアドレス",
    "new_email": "新メールアドレス",
    "msg update ok": "正常に変更しました。",
    "msg create ok": "正常に登録しました。",
    "msg delete ok": "正常に削除しました。",
    "delete confirm": "削除しますがよろしいでしょうか。",
    "delete confirm title": "削除",
    "view": "見る",
    "edit": "編集",
    "delete": "削除",
    "information of": "{entry}の詳細情報",
    "loading": "表示件数。。。",
    "list empty": "データがありません。",
    "start_date": "開始日",
    "end_date": "終了日",
    "price": "料金",
    "taxi_type": "車種",
    "start_point": "出発地",
    "hotel_room_type": "部屋タイプ",
    "hotel_people_fee_rate": "1室の人数",
    "start point": "出発地",
    "upload submit": "アップロード",
    "choose file": "ファイル選択",
    "import csv": "CSVで追加",
    "download csv": "CSVダウンロード",
    "file ext err": "ファイルタイプが正しくありません。csvタイプのファイルをアップロードしてください。",
    "import success": "ファイルのアップロードが成功しました。",
    "import err": "ファイルのアップロードが失敗しました。",
    "area name": "宿泊エリア",
    "area code": "宿泊エリアコード",
  },
  "auth": {
    "login": "ログインする",
    "login page": "ログイン",
    "forgot password page": "パスワードをリセットする ",
    "forgot password note": "メールでパスワードリセット用のURLを送信します。",
    "verify ok": "登録していただいたメールアドレスが確認されました。",
    "reset pass ok": "パスワードが正常に再設定されました。",
    "reset password page": "パスワードリセット ",
    "update password": "リセット",
    "change email": "メールアドレス変更",
    "change password": "パスワード変更",
    "change password ok": "パスワードが変更されました。",
    "change password fail": "パスワードの変更が失敗しました。",
    "msg update email ok": "メールアドレスが変更されました。",
    "msg update email fail": "メールアドレスの変更が失敗しました。",
    "basic profile": "基本情報",
    "profile": "プロファイル",
    "reset password": "再設定",
  },
  "dialog": {
    "confirm title": "Confirmation Dialog",
    "confirm body": "Are you sure do this action?"
  },
  "nav": {
    "dashboard": "ダッシュボード",
    "logout": "ログアウト",
    "user list": "ユーザー一覧",
    "hotel management": "ホテル管理",
    "hotel": "ホテル一覧画面",
    "hotel account": "ホテルアカウント管理",
    "taxi company management": "タクシー会社管理",
    "taxi company": "タクシー一覧画面",
    "taxi account": "タクシーアカウント管理",
    "admin account": "Adminアカウント管理",
    "settings": "各種設定",
    "profile": "プロファイル",
    "master setting": "マスター設定",
    "start point": "出発地設定",
    "fee child rule": "子供料金ルール設定",
    "taxi branch": "事業所管理",
    "taxi type list": "タクシー車種管理",
    "tourist option": "観光オプション料金管理",
    "taxi fee rule": "タクシー料金管理",
    "hotel room type": "部屋タイプ管理",
    "hotel fee rule": "宿泊料金管理",
    "Bookings": "予約管理",
    "Available taxi": "タクシー在庫",
    "Analysis": "集計",
    "Hotel info": "ホテル情報",
    "Taxi info": "タクシー会社情報",
    "cs account": "予約センターアカウント管理",
    "area": "宿泊エリア設定",
  },
  "breadcrumbs": breadcrumbs,
  "users": {
    "admin user list": "Adminアカウント管理",
    "create user": "アカウント登録",
    "create hotel user": "ホテルアカウント登録",
    "create taxi user": "タクシーアカウント登録",
    "update user": "アカウント編集",
  },
  "hotel": {
    "hotel_name": "ホテル名",
    "room type": "部屋タイプ",
    "create hotel": "ホテル登録",
    "update hotel": "ホテル編集",
    "hotel user list": "ホテルアカウント管理",
    "hotel list": "ホテル一覧",
    "room type list": "部屋タイプ管理",
    "create room type": "部屋タイプの登録",
    "update room type": "部屋タイプの編集",
    "date type normal": "平日",
    "date type holiday": "休前日",
    "date type special": "特日",
    "fee_rate": "宿泊料金",
    "fee_rate_driver": "ドライバー様宿泊料金",
    "fee rule list": "宿泊料金管理画面",
    "create fee rule": "宿泊料金の登録",
    "update fee rule": "宿泊料金の編集",
    "fee rule": "宿泊料金",
    "info": "ホテル情報",
    "import fee rule": "宿泊料金のファイルをアップロード",
    "plan name": "プラン名"
  },
  "taxi": {
    "code": "会社コード",
    "info": "タクシー会社情報",
    "taxi name": "タクシー会社名",
    "branch name": "事業所名",
    "taxi type": "車種名",
    "list": "タクシー会社一覧",
    "create taxi": "タクシー会社登録",
    "taxi company name": "タクシー会社名",
    "taxi user list": "タクシーアカウント管理",
    "update taxi": "タクシー会社編集",
    "branch label": "事業所",
    "taxi type label": "車種",
    "taxi type list": "タクシー車種管理画面",
    "tourist option": "観光オプション料金",
    "fee rule": "タクシー料金",
    "taxi fee rule": "タクシー料金管理画面",
    "TO name": "コース名",
    "TO plan": "プラン",
    "TO price": "料金",
    "TO people": "人数",
    "TO time": "時間（h）",
    "TO plan 1": "片道",
    "TO plan 2": "往復",
    "tourist option list": "観光オプション料金管理画面",
    "end_point": "送り先",
    "create taxi branch": "事業所の登録",
    "update taxi branch": "事業所の編集",
    "create taxi type": "タクシー車種の登録",
    "update taxi type": "タクシー車種の編集",
    "create tourist option": "観光オプション登録",
    "update tourist option": "観光オプション編集",
    "create fee rule": "タクシー料金の登録",
    "update fee rule": "タクシー料金の編集",
    "branch list": "事業所管理",
    "available label 1": "台数",
    "available label 2": "受",
    "available label 3": "残り",
    "date month": "対象月",
    "register available": "タクシー在庫",
    "taxi available type 1": "タクシー台数",
    "taxi available type 2": "チャイルドシート台数",
    "taxi available date": "対象日付",
    "taxi available type": "登録内容",
    "cars num": "台数 ",
    "import fee rule": "タクシー料金のファイルをアップロード",
    "available taxi": "タクシー在庫",
    "taxi cars num": "在庫数",
    "plan name": "プラン名",
    "kidchair_num_default": "チャイルドシート台数"
  },
  "setting": {
    "start point list": "出発地設定",
    "fee child rule list": "子供料金ルール設定",
    "fee child rule rate 1 label": "小学生(大人食)料金",
    "fee child rule rate 2 label": "小学生(子供食)料金",
    "fee child rule rate 3 label": "未就学児（3～5歳）料金",
    "fee child rule rate 4 label": "乳児（0～2歳）料金",
    "create start point": "出発地の登録",
    "update start point": "出発地の編集",
    "create fee child rule": "子供料金ルールの登録",
    "update fee child rule": "子供料金ルールの編集",
    "create area": "宿泊エリアの登録",
    "update area": "宿泊エリアの編集",
  },
  "booking": {
    "status": "ステータス",
    "c_name": "利用代表者様",
    "c_name_kana": "フリガナ",
    "c_phone": "電話番号",
    "checkin_taxi_date": "出発日付",
    "booking_code": "予約コード",
    "checkin_date": "チェックイン日",
    "checkout_date": "チェックアウト日",
    "date_rank": "日付ランク",
    "date_rank_select": "登録",
    "booking_date": "予約受付日",
    "room num": "部屋数",
    "room type people": "数量",
    "c_gender": "性別",
    "c_postal_code": "郵便番号",
    "c_address": "住所",
    "c_phone_create": "連絡先（電話）",
    "c_phone_mobile": "（携帯）",
    "c_phone_home": "（自宅）",
    "c_phone_note": "[郵便番号で自動表示される]",
    "c_num_1": "大人（内訳不明）",
    "c_num_2": "大人（男性）",
    "c_num_3": "大人（女性）",
    "c_num_4": "お子様（小学生）",
    "c_num_5": "お子様（未就学児）",
    "c_num_6": "お子様（0～2歳）",
    "c_num_total": "合計",
    "c_note": "備考",
    "c_price_1": "大人",
    "c_price_2": "小学生(大人食)",
    "c_price_3": "小学生(子供食)",
    "c_price_4": "お子様(未就学児)",
    "c_price_5": "お子様(0～2歳)",
    "c_price_driver": "ドライバー様宿泊料金",
    "c_meal_1": "大人用",
    "c_meal_2": "お子様用",
    "meal_allergy_notes": "[備考]",
    "c_price_total": "合計",
    "c_age_notes": "【その他・備考】",
    "checkin_taxi_datetime": "往路日/お迎え時刻",
    "checkout_taxi_datetime": "復路日/現地出発時刻",
    "pickup_point": "お迎え場所",
    "pickup_point_notes": "お迎え住所",
    "use_wheelchair": "車椅子",
    "wheelchair_notes": "備考",
    "use_kidchair": "チャイルドシート",
    "kidchair_num": "チャイルドシート台数",
    "taxi_notes": "その他",
    "kid_notes": "［ご利用になるお子様の年齢と身長を入力してください。］",
    "taxi_price": "タクシー料金",
    "driver_stay_info": "ドライバー様宿泊先",
    "payment_method": "ドライバー様宿泊料金支払い区分を新設",
    "use_tourist_option": "観光オプション",
    "total_price_hotel": "宿泊料金合計",
    "total_price_taxi": "タクシー料金合計",
    "total_price_tourist_options": "オプション料金",
    "total_price_discount": "割引料金",
    "total_price_final": "合計",
    "driver_name": "担当乗務員",
    "driver_phone": "携帯電話番号",
    "driver_langs": "乗務員使用言語",
    "c_langs": "お客様使用言語",
    "driver_license_num": "車番",
    "taxi_plan": "プラン種別",
    "driver_taxi_type_name": "希望車種",
    "taxi_branch_name": "所属事業所の情報",
    "driver_taxi_type_id": "希望車種",
    "driver_offer_note": "乗務員リクエスト",
    "note_driver_ui": "<<大和自動車交通株式会社様 確認>>",
    "c_phone_list": "代表者様電話番号",
    "label_taxi_checkin_date": "配車日"
  }
}
