import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

const routerView = {
  render(c) {
    return c("router-view");
  }
}
export default [
  {
    path: 'taxi',
    name: ROUTES.ADMIN.TAXI_LIST,
    meta: {breadcrumb: i18n.tc('breadcrumbs.Taxi companies'), activeKey: 'admin.taxi.list'},
    component: routerView,
    children: [
      {
        path: '',
        name: ROUTES.ADMIN.TAXI_LIST,
        meta: {activeKey: 'admin.taxi.list'},
        component: () => import("@components/admin/taxis/taxi_company/ListTaxiCompany"),
      },
      {
        path: ':id/branchs',
        name: ROUTES.ADMIN.TAXI_BRANCH_LIST,
        meta: {
          breadcrumb: ['{taxi_company}', i18n.tc('breadcrumbs.Taxi branchs')],
          activeKey: 'admin.taxi.list'
        },
        component: () => import("@components/admin/taxis/branchs/ListTaxiBranch"),
      },
      {
        path: ':id/types',
        name: ROUTES.ADMIN.TAXI_TYPE_LIST,
        meta: {
          breadcrumb: ['{taxi_company}', i18n.tc('breadcrumbs.Taxi types')],
          activeKey: 'admin.taxi.list'
        },
        component: () => import("@components/admin/taxis/taxi_types/ListTaxiType"),
      },
      {
        path: ':id/tourist-options',
        name: ROUTES.ADMIN.TOURIST_OPTION,
        meta: {
          breadcrumb: ['{taxi_company}', i18n.tc('breadcrumbs.Tourist option')],
          activeKey: 'admin.taxi.list'
        },
        component: () => import("@components/admin/taxis/tourist_options/ListTouristOption"),
      },
      {
        path: ':id/taxi-fee-rules',
        name: ROUTES.ADMIN.TAXI_FEE_RULE,
        meta: {
          breadcrumb: ['{taxi_company}', i18n.tc('breadcrumbs.Taxi fee rules')],
          activeKey: 'admin.taxi.list'
        },
        component: () => import("@components/admin/taxis/taxi_fee_rules/ListTaxiFeeRule"),
      },
    ],
  },
  {
    path: 'available-taxi',
    name: ROUTES.ADMIN.AVAILABLE_TAXI,
    meta: {
      breadcrumb: i18n.tc('breadcrumbs.Available taxi'),
      activeKey: 'admin.taxi.available'
    },
    component: () => import("@components/admin/taxis/available_taxi/AvailableTaxi"),
  },
];
