/*admin route*/
import AdminRoute from './admin';

/*cs route*/
import CSRoute from './cs';

/*Taxi route*/
import TaxiRoute from './taxi';

/*Hotel route*/
import HotelRoute from './hotel';

/*exception route*/
import VueRouter from 'vue-router';
import ErrorsRoute from './errors/ErrorsRoute';

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...ErrorsRoute,
    ...AdminRoute,
    ...CSRoute,
    ...HotelRoute,
    ...TaxiRoute,
  ]
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
