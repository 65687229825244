import API from '@consts/api';
import ROUTERS from '@consts/routes';

export default {
  install(Vue, options) {
    const app = new Vue;
    Vue.prototype.$auth = {
      login: async function (data, authKey = 'adminAuth') {
        const response = await Vue.prototype.$request.post(API.AUTH.LOGIN, data)
        if (response?.headers?.authorization) {
          app.$store.commit(`${authKey}/updateToken`, {
            token: response.headers.authorization,
            expire_time: response.headers['token-expire-time']
          })
          app.$store.commit(`${authKey}/updateUser`, response.data.data)
        }
        return response;
      },

      logout: async function (authKey = 'adminAuth') {
        await Vue.prototype.$request.delete(API.AUTH.LOGOUT)
        app.$store.commit(`${authKey}/removeUser`)
        app.$store.commit(`${authKey}/removeToken`)
        let routeLogin = ROUTERS.ADMIN.AUTH.LOGIN;
        switch (authKey) {
          case 'adminAuth':
            routeLogin = ROUTERS.ADMIN.AUTH.LOGIN;
            break;
          case 'csAuth':
            routeLogin = ROUTERS.CS.AUTH.LOGIN;
            break;
          case 'hotelAuth':
            routeLogin = ROUTERS.HOTEL.AUTH.LOGIN;
            break;
          case 'taxiAuth':
            routeLogin = ROUTERS.TAXI.AUTH.LOGIN;
            break;
        }
        Vue.router.pushByName(routeLogin)
      },

      user: function (authKey = 'adminAuth') {
        return app.$store.state[authKey].user
      },
    }
  }
}
