import {AppLocalStorage} from "@services/utils";

export const updateUser = (state, opened) => {
  state.user = opened
  AppLocalStorage.setItem('adminAuth.user', JSON.stringify(state.user));
}
export const removeUser = (state) => {
  state.user = null
  AppLocalStorage.removeItem('adminAuth.user');
}
export const updateToken = (state, opened) => {
  const now = new Date();
  const expireTime = now.setTime(now.getTime() + Number(opened.expire_time))
  state.token = opened.token
  state.expire_time = expireTime
  AppLocalStorage.setItem('adminAuth.token', state.token);
  AppLocalStorage.setItem('adminAuth.expire_time', String(expireTime));
}
export const removeToken = (state) => {
  state.token = null
  state.expire_time = null
  AppLocalStorage.removeItem('adminAuth.token');
  AppLocalStorage.removeItem('adminAuth.expire_time');
}
