<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors, invalid, validated }">
    <div :class="[inputStyle === 'normal' ? '' : 'app-form-group row', 'form-group', boundaryClass]">
      <label
        :class="[inputStyle === 'normal' ? '' : 'col-md-4', 'control-label input-label p-0', labelClass]">{{ label }}
        <span class="color-active"
              v-if="rules && rules.includes('required') && !rules.includes('required_if')"> ＊</span></label>
      <div
        :class="[inputStyle === 'normal' ? '' : 'col-md-8', 'form-group m-b-0 p-0',invalid && validated ? 'has-error' : '']">
        <div :class="[$slots.append ? 'input-group' : '']">
          <select class="form-control" ref='select' :value="value" :disabled="disabled">
            <option v-if="!value"></option>
            <template v-if="optionsData">
              <option v-for="item in optionsData" :value="item.id">
                {{ item.name }}
              </option>
            </template>
            <slot></slot>
          </select>
        </div>
        <label v-if="invalid && validated" class="error app-input-error-label-2">{{ errors[0] }}</label>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>

export default {
  props: [
    'options',
    'value',
    'name',
    'label',
    'rules',
    'value',
    'optionsData',
    'inputStyle',
    'boundaryClass',
    'labelClass',
    'onChange',
    'disabled',
  ],
  mounted: function () {
    this.initSelect()
  },
  methods: {
    initSelect() {
      let vm = this;
      var onChange = this.onChange;
      $(this.$refs.select).val(this.value);
      $(this.$refs.select)
        .select2({
          ...this.options,
          "language": {
            "noResults": function () {
              return '該当するデータが見つかりませんでした。';
            }
          },
        })
        .on('change', function (ev, args) {
          if (!(args && "ignore" in args)) {
            if (onChange) {
              onChange($(this).val());
            }
            vm.$emit('input', $(this).val());
          }
        });
      if (this.options?.containerCssClass) {
        $('.' + this.options?.containerCssClass).closest('.select2-container').addClass(this.options?.containerCssClass);
      }
    }
  },
  watch: {
    /*on change value*/
    value: function (value, oldValue) {
      let vm = this;
      $(this.$refs.select)
        .val(this.value)
        .trigger('change', {ignore: true});
      vm.$emit('change', this.value);
    },
    /*on change options*/
    options: function (options, oldOptions) {
      if (options && JSON.stringify(options) !== JSON.stringify(oldOptions)) {
        $(this.$refs.select).select2(options)
      }
    },
    optionsData: function (value, oldValue) {
      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        this.$nextTick(() => {
          $(this.$refs.select).off().select2('destroy');
          this.initSelect()
          $(this.$refs.select).val(this.value).trigger('change')
        })
      }
    }
  },
  destroyed: function () {
    $(this.$refs.select).off().select2('destroy');
  },
}
</script>
