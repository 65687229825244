import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

const routerView = {
  render(c) {
    return c("router-view");
  }
}
export default [
  {
    path: 'bookings',
    name: ROUTES.CS.BOOKING_LIST,
    meta: {
      breadcrumb: i18n.tc('breadcrumbs.Booking'),
      activeKey: 'cs.booking.list'
    },
    component: routerView,
    children: [
      {
        path: '',
        name: ROUTES.CS.BOOKING_LIST,
        meta: {
          activeKey: 'cs.booking.list'
        },
        component: () => import("@components/cs/bookings/ListBooking"),
      },
      {
        path: 'create',
        name: ROUTES.CS.BOOKING_CREATE,
        meta: {breadcrumb: i18n.tc('breadcrumbs.Booking create'), activeKey: 'cs.booking.list'},
        component: () => import("@components/cs/bookings/BookingCreate"),
      },
      {
        path: ':id/edit',
        name: ROUTES.CS.BOOKING_EDIT,
        meta: {breadcrumb: i18n.tc('breadcrumbs.Booking edit'), activeKey: 'cs.booking.list'},
        component: () => import("@components/cs/bookings/BookingEdit"),
      },
      {
        path: 'create_from/:id',
        name: ROUTES.CS.BOOKING_COPY_CREATE,
        meta: {breadcrumb: i18n.tc('breadcrumbs.Booking create'), activeKey: 'cs.booking.list'},
        component: () => import("@components/cs/bookings/BookingCopyCreate"),
      },
      {
        path: 'history/:id',
        name: ROUTES.CS.BOOKING_HISTORY_LIST,
        meta: {breadcrumb: i18n.tc('breadcrumbs.Booking history'), activeKey: 'cs.booking.list'},
        component: () => import("@components/cs/bookings/ListBookingHistory"),
      },
    ]
  },
];
