import TaxiAccess from "@services/middlewares/TaxiAccess";
import DashboardRoute from "./DashboardRoute";
import ROUTES from "@consts/routes";
import TaxiAuthAxios from "@services/middlewares/TaxiAuthAxios";
import AuthRoute from "@routes/taxi/AuthRoute";
import ProfileRoute from "@routes/taxi/ProfileRoute";
import i18n from "@/lang/i18n";
import BookingRoute from "@routes/taxi/BookingRoute";

export default [
  ...AuthRoute,
  {
    path: '/taxi',
    meta: {
      middlewares: [TaxiAuthAxios, TaxiAccess],
      breadcrumb: i18n.tc('breadcrumbs.Home'),
      activeKey: 'taxi.dashboard'
    },
    name: ROUTES.TAXI.DASHBOARD,
    component: () => import('@components/_partials/Layout'),
    children: [
      {
        path: '',
        redirect: {name: ROUTES.TAXI.DASHBOARD}
      },
      ...DashboardRoute,
      ...ProfileRoute,
      ...BookingRoute,
      {
        path: 'branchs',
        name: ROUTES.TAXI.TAXI_BRANCH_LIST,
        meta: {
          breadcrumb: ['{taxi_company}', i18n.tc('breadcrumbs.Taxi branchs')],
          activeKey: 'taxi.branchs.list'
        },
        component: () => import("@components/taxi/branchs/ListTaxiBranch"),
      },
      {
        path: 'types',
        name: ROUTES.TAXI.TAXI_TYPE_LIST,
        meta: {
          breadcrumb: ['{taxi_company}', i18n.tc('breadcrumbs.Taxi types')],
          activeKey: 'taxi.types.list'
        },
        component: () => import("@components/taxi/taxi_types/ListTaxiType"),
      },
      {
        path: 'tourist-options',
        name: ROUTES.TAXI.TOURIST_OPTION,
        meta: {
          breadcrumb: ['{taxi_company}', i18n.tc('breadcrumbs.Tourist option')],
          activeKey: 'taxi.tourist-options.list'
        },
        component: () => import("@components/taxi/tourist_options/ListTouristOption"),
      },
      {
        path: 'taxi-fee-rules',
        name: ROUTES.TAXI.TAXI_FEE_RULE,
        meta: {
          breadcrumb: ['{taxi_company}', i18n.tc('breadcrumbs.Taxi fee rules')],
          activeKey: 'taxi.fee-rules.list'
        },
        component: () => import("@components/taxi/taxi_fee_rules/ListTaxiFeeRule"),
      },
      {
        path: 'taxi-info',
        name: ROUTES.TAXI.INFO,
        meta: {
          breadcrumb: i18n.tc('breadcrumbs.Taxi info'),
          activeKey: 'taxi.info'
        },
        component: () => import("@components/taxi/info/TaxiInfo"),
      },
      {
        path: 'taxi-available',
        name: ROUTES.TAXI.AVAILABLE_TAXI,
        meta: {
          breadcrumb: i18n.tc('breadcrumbs.Available taxi'),
          activeKey: 'taxi.available'
        },
        component: () => import("@components/taxi/available_taxi/AvailableTaxi"),
      },
    ],
  }
]
