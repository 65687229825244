<template>
  <router-view/>
</template>

<script>
export default {
  watch: {
    '$route'(to, from) {
      // document.title = to.meta?.title || 'Taxi Inventory';
    },
  },
}
</script>
<style lang="scss">
@import "@assets/template_custom.scss";
@import "@assets/styles_v2.scss";
</style>
