import api from '@consts/api'
import role from '@consts/role'
import booking from '@consts/booking'
import routes from '@consts/routes'

export default {
  API: api,
  BOOKING: booking,
  ROLES: role,
  ROUTES: routes,
}
