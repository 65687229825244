import AdminAccess from "@services/middlewares/AdminAccess";
import DashboardRoute from "./DashboardRoute";
import ROUTES from "@consts/routes";
import UserRoute from "@routes/admin/UserRoute";
import HotelRoute from "@routes/admin/HotelRoute";
import TaxiRoute from "@routes/admin/TaxiRoute";
import AdminAuthAxios from "@services/middlewares/AdminAuthAxios";
import AuthRoute from "@routes/admin/AuthRoute";
import ProfileRoute from "@routes/admin/ProfileRoute";
import i18n from "@/lang/i18n";
import MasterSettingRoute from "@routes/admin/MasterSettingRoute";
import BookingRoute from "@routes/admin/BookingRoute";
import AnalysisRoute from "@routes/admin/AnalysisRoute";

export default [
  ...AuthRoute,
  {
    path: '/admin',
    meta: {
      middlewares: [AdminAuthAxios, AdminAccess],
      breadcrumb: i18n.tc('breadcrumbs.Home'),
      activeKey: 'admin.dashboard'
    },

    name: ROUTES.ADMIN.HOME,
    component: () => import('@components/_partials/Layout'),
    children: [
      {
        path: '',
        redirect: {name: ROUTES.ADMIN.DASHBOARD}
      },
      ...DashboardRoute,
      ...UserRoute,
      ...HotelRoute,
      ...TaxiRoute,
      ...ProfileRoute,
      ...MasterSettingRoute,
      ...BookingRoute,
      ...AnalysisRoute,
    ],
  }
]
