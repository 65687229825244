export default {
  METHOD_GET: 'GET',
  METHOD_POST: 'POST',
  METHOD_UPDATE: 'PATCH',
  METHOD_DELETE: 'DELETE',
  UNAUTHORIZED_CODE: 401,
  FORBIDDEN_CODE: 403,
  NOTFOUND_CODE: 404,
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REFRESH: '/auth/refresh',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    CHECK_PARAMS_RESET_PASSWORD: '/auth/check-params-reset-password',
  },
  PROFILE: {
    USER: '/profile/user',
    UPDATE_PROFILE: '/profile/update_profile',
  },
  ADMIN: {
    // USER
    USERS_LIST: '/users',
    USERS_DELETE: id => `/users/${id}`,
    USERS_EDIT: id => `/users/${id}`,
    USERS_SAVE: '/users',

    // HOTEL
    HOTEL_LIST: '/hotels',
    HOTEL_LIST_SELECT: '/hotels_select',
    HOTEL_DETAIL: id => `/hotels/${id}`,
    HOTEL_DELETE: id => `/hotels/${id}`,
    HOTEL_EDIT: id => `/hotels/${id}`,
    HOTEL_SAVE: '/hotels',
    HOTEL_ROOM_TYPE_LIST: '/hotel_room_types',
    HOTEL_ROOM_TYPE_SELECT: hotelId => `/hotel_room_types_select/${hotelId}`,
    HOTEL_ROOM_TYPE_SAVE: '/hotel_room_types',
    HOTEL_ROOM_TYPE_EDIT: id => `/hotel_room_types/${id}`,
    HOTEL_ROOM_TYPE_DELETE: id => `/hotel_room_types/${id}`,
    HOTEL_FEE_RULE_LIST: '/hotel_fee_rules',
    HOTEL_FEE_RULE_SAVE: '/hotel_fee_rules',
    HOTEL_FEE_RULE_SHOW: id => `hotel_fee_rules_show/${id}`,
    HOTEL_FEE_RULE_EDIT: id => `/hotel_fee_rules/${id}`,
    HOTEL_FEE_RULE_DELETE: id => `/hotel_fee_rules/${id}`,
    IMPORT_HOTEL_FEE_RULE: `/import_hotel_fee_rules`,
    DOWNLOAD_CSV_HOTEL_FEE_RULE: `/download_hotel_fee_rules`,

    // TAXI
    TAXI_LIST: '/taxi_companies',
    TAXI_LIST_SELECT: '/taxi_companies_select',
    TAXI_DELETE: id => `/taxi_companies/${id}`,
    TAXI_EDIT: id => `/taxi_companies/${id}`,
    TAXI_DETAIL: id => `/taxi_companies/${id}`,
    TAXI_SAVE: '/taxi_companies',
    TAXI_BRANCH_LIST: `/taxi_branches`,
    TAXI_BRANCH_LIST_SELECT: `/taxi_branches_select`,
    TAXI_BRANCH_SAVE: `/taxi_branches`,
    TAXI_BRANCH_EDIT: id => `/taxi_branches/${id}`,
    TAXI_BRANCH_DELETE: id => `/taxi_branches/${id}`,
    TAXI_TYPE_LIST: `/taxi_types`,
    TAXI_TYPE_SELECT: taxi_company_id => `/taxi_types_select/${taxi_company_id}`,
    TAXI_TYPE_SAVE: `/taxi_types`,
    TAXI_TYPE_EDIT: id => `/taxi_types/${id}`,
    TAXI_TYPE_DELETE: id => `/taxi_types/${id}`,
    TOURIST_OPTION_LIST: `/tourist_options`,
    TOURIST_OPTION_LIST_SELECT: `/tourist_options_select`,
    TOURIST_OPTION_SAVE: `/tourist_options`,
    TOURIST_OPTION_UPDATE: id => `/tourist_options/${id}`,
    TOURIST_OPTION_DELETE: id => `/tourist_options/${id}`,
    TAXI_FEE_RULE_LIST: `/taxi_fee_rules`,
    TAXI_FEE_RULE_SAVE: `/taxi_fee_rules`,
    TAXI_FEE_RULE_UPDATE: id => `/taxi_fee_rules/${id}`,
    TAXI_FEE_RULE_DELETE: id => `/taxi_fee_rules/${id}`,
    TAXI_AVAILABLE: `/taxi_available`,
    TAXI_AVAILABLE_REGISTER: `/taxi_available`,
    IMPORT_TAXI_FEE_RULE: `/import_taxi_fee_rules`,
    DOWNLOAD_CSV_TAXI_FEE_RULE: `/download_taxi_fee_rules`,

    START_POINT_LIST: `/start_points`,
    START_POINT_SAVE: `/start_points`,
    START_POINT_EDIT: id => `/start_points/${id}`,
    START_POINT_DELETE: id => `/start_points/${id}`,
    START_POINT_LIST_SELECT: `/start_points_select`,
    AREA_LIST: `/areas`,
    AREA_SAVE: `/areas`,
    AREA_EDIT: id => `/areas/${id}`,
    AREA_DELETE: id => `/areas/${id}`,
    AREA_LIST_SELECT: `/areas_select`,
    FEE_CHILD_RULE_DEFAULT: `/fee_child_rules_default`,
    UPDATE_FEE_CHILD_RULE_DEFAULT: `/fee_child_rules_default`,
    FEE_CHILD_RULE_LIST: `/fee_child_rules`,
    FEE_CHILD_RULE_SAVE: `/fee_child_rules`,
    FEE_CHILD_RULE_EDIT: id => `/fee_child_rules/${id}`,
    FEE_CHILD_RULE_DELETE: id => `/fee_child_rules/${id}`,
    BOOKING_RELATE_DATA: '/booking_relate_data',
    BOOKING_LIST: '/bookings',
    BOOKING_SAVE: '/bookings',
    BOOKING_SAVE_DRAFT: '/bookings_draft',
    BOOKING_EDIT_DRAFT: id => `/bookings_draft/${id}`,
    BOOKING_SHOW: id => `/bookings/${id}`,
    BOOKING_COPY: id => `/bookings_copy/${id}`,
    BOOKING_EDIT: id => `/bookings/${id}`,
    BOOKING_DELETE: id => `/bookings/${id}`,
    GET_BOOKING_FORM_URL: 'bookings_export_form',
    BOOKING_HISTORY_LIST: 'booking_histories',
    DATE_RANK_LIST: 'date_ranks',
    DATE_RANK_UPDATE: 'date_ranks/create_or_update',
    BOOKING_TAXI_TYPE: 'bookings_taxi_types',
    BOOKING_CHECK_KIDCHAIR: 'bookings_check_kidchair',
    HOTEL_FEE_RULE_FIND: `hotel_fee_rules_find`,
    TAXI_FEE_RULE_FIND: `taxi_fee_rules_find`,
    POSTAL_CODE_FIND: code => `postal_code/${code}`,
    ANALYSIS_BY_START_POINT: 'analysis/start_points',
    ANALYSIS_EXPORT_START_POINT: 'analysis/get_export_start_points',
    ANALYSIS_BY_HOTEL: 'analysis/hotels',
    ANALYSIS_EXPORT_HOTEL: 'analysis/get_export_hotels',
    ANALYSIS_BY_BOOKING: 'analysis/bookings',
    ANALYSIS_EXPORT_BOOKING: 'analysis/get_export_bookings',
    RESENT_ACTIVE_EMAIL: userId => `resent_active_email/${userId}`,
  },
  CS: {
    // TAXI
    TAXI_AVAILABLE: `/taxi_available`,
    TAXI_AVAILABLE_REGISTER: `/taxi_available`,
    TAXI_TYPE_SELECT: taxi_company_id => `/taxi_types_select/${taxi_company_id}`,
    TOURIST_OPTION_LIST_SELECT: `/tourist_options_select`,
    TAXI_LIST_SELECT: '/taxi_companies_select',

    HOTEL_ROOM_TYPE_SELECT: hotelId => `/hotel_room_types_select/${hotelId}`,
    BOOKING_RELATE_DATA: '/booking_relate_data',
    BOOKING_LIST: '/bookings',
    BOOKING_SAVE: '/bookings',
    BOOKING_SAVE_DRAFT: '/bookings_draft',
    BOOKING_EDIT_DRAFT: id => `/bookings_draft/${id}`,
    BOOKING_SHOW: id => `/bookings/${id}`,
    BOOKING_COPY: id => `/bookings_copy/${id}`,
    BOOKING_EDIT: id => `/bookings/${id}`,
    BOOKING_DELETE: id => `/bookings/${id}`,
    GET_BOOKING_FORM_URL: 'bookings_export_form',
    BOOKING_HISTORY_LIST: 'booking_histories',
    DATE_RANK_LIST: 'date_ranks',
    DATE_RANK_UPDATE: 'date_ranks/create_or_update',
    BOOKING_TAXI_TYPE: 'bookings_taxi_types',
    BOOKING_CHECK_KIDCHAIR: 'bookings_check_kidchair',
    HOTEL_FEE_RULE_FIND: `hotel_fee_rules_find`,
    TAXI_FEE_RULE_FIND: `taxi_fee_rules_find`,
    POSTAL_CODE_FIND: code => `postal_code/${code}`,
    ANALYSIS_BY_START_POINT: 'analysis/start_points',
    ANALYSIS_EXPORT_START_POINT: 'analysis/get_export_start_points',
    ANALYSIS_BY_HOTEL: 'analysis/hotels',
    ANALYSIS_EXPORT_HOTEL: 'analysis/get_export_hotels',
    ANALYSIS_BY_BOOKING: 'analysis/bookings',
    ANALYSIS_EXPORT_BOOKING: 'analysis/get_export_bookings',
  },
  TAXI: {
    TAXI_COMPANY_SHOW: `/taxi_info`,
    TAXI_COMPANY_UPDATE: `/taxi_info`,
    TAXI_BRANCH_LIST: `/taxi_branches`,
    TAXI_BRANCH_SAVE: `/taxi_branches`,
    TAXI_BRANCH_EDIT: id => `/taxi_branches/${id}`,
    TAXI_BRANCH_DELETE: id => `/taxi_branches/${id}`,
    TAXI_TYPE_LIST: `/taxi_types`,
    TAXI_TYPE_SELECT: `/taxi_types_select`,
    TAXI_TYPE_SAVE: `/taxi_types`,
    TAXI_TYPE_EDIT: id => `/taxi_types/${id}`,
    TAXI_TYPE_DELETE: id => `/taxi_types/${id}`,
    TOURIST_OPTION_LIST: `/tourist_options`,
    TOURIST_OPTION_SAVE: `/tourist_options`,
    TOURIST_OPTION_UPDATE: id => `/tourist_options/${id}`,
    TOURIST_OPTION_DELETE: id => `/tourist_options/${id}`,
    TAXI_FEE_RULE_LIST: `/taxi_fee_rules`,
    TAXI_FEE_RULE_SAVE: `/taxi_fee_rules`,
    TAXI_FEE_RULE_UPDATE: id => `/taxi_fee_rules/${id}`,
    TAXI_FEE_RULE_DELETE: id => `/taxi_fee_rules/${id}`,
    TAXI_AVAILABLE: `/taxi_available`,
    TAXI_AVAILABLE_REGISTER: `/taxi_available`,
    START_POINT_LIST_SELECT: `/start_points_select`,
    HOTEL_LIST_SELECT: '/hotels_select',
    IMPORT_TAXI_FEE_RULE: `/import_taxi_fee_rules`,
    DOWNLOAD_CSV_TAXI_FEE_RULE: `/download_taxi_fee_rules`,
    BOOKING_LIST: '/bookings',
    BOOKING_SHOW: id => `/bookings/${id}`,
    BOOKING_EDIT: id => `/bookings/${id}`,
    BOOKING_TAXI_TYPE: 'bookings_taxi_types',
    BOOKING_CHECK_KIDCHAIR: 'bookings_check_kidchair',
    TAXI_FEE_RULE_FIND: `taxi_fee_rules_find`,
    BOOKING_RELATE_DATA: '/booking_relate_data',
    GET_BOOKING_FORM_URL: 'bookings_export_form',
    BOOKING_HISTORY_LIST: 'booking_histories',
  },
  HOTEL: {
    HOTEL_SHOW: `/hotel_info`,
    HOTEL_UPDATE: `/hotel_info`,
    HOTEL_ROOM_TYPE_LIST: '/hotel_room_types',
    HOTEL_ROOM_TYPE_SELECT: `/hotel_room_types_select`,
    HOTEL_ROOM_TYPE_SAVE: '/hotel_room_types',
    HOTEL_ROOM_TYPE_EDIT: id => `/hotel_room_types/${id}`,
    HOTEL_ROOM_TYPE_DELETE: id => `/hotel_room_types/${id}`,
    HOTEL_FEE_RULE_LIST: '/hotel_fee_rules',
    HOTEL_FEE_RULE_SHOW: id => `hotel_fee_rules_show/${id}`,
    HOTEL_FEE_RULE_SAVE: '/hotel_fee_rules',
    HOTEL_FEE_RULE_EDIT: id => `/hotel_fee_rules/${id}`,
    HOTEL_FEE_RULE_DELETE: id => `/hotel_fee_rules/${id}`,
    IMPORT_HOTEL_FEE_RULE: `/import_hotel_fee_rules`,
    BOOKING_RELATE_DATA: '/booking_relate_data',
    DOWNLOAD_CSV_HOTEL_FEE_RULE: `/download_hotel_fee_rules`,
    BOOKING_LIST: '/bookings',
    BOOKING_SHOW: id => `/bookings/${id}`,
    BOOKING_EDIT: id => `/bookings/${id}`,
    GET_BOOKING_FORM_URL: 'bookings_export_form',
    AREA_LIST_SELECT: `/areas_select`,
    BOOKING_HISTORY_LIST: 'booking_histories',
  }
}
