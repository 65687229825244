import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

const routerView = {
  render(c) {
    return c("router-view");
  }
}

export default [
  {
    path: 'hotels',
    name: ROUTES.ADMIN.HOTEL_LIST,
    meta: {breadcrumb: i18n.tc('breadcrumbs.Hotels'), activeKey: 'admin.hotel.list'},
    component: routerView,
    children: [
      {
        path: '',
        name: ROUTES.ADMIN.HOTEL_LIST,
        meta: {activeKey: 'admin.hotel.list'},
        component: () => import("@components/admin/hotels/ListHotel"),
      },
      {
        path: ':id/room_types',
        name: ROUTES.ADMIN.HOTEL_ROOM_TYPE_LIST,
        meta: {
          breadcrumb: ['{hotel}', i18n.tc('breadcrumbs.Hotel room type')],
          activeKey: 'admin.hotel.list'
        },
        component: () => import("@components/admin/hotels/room_types/ListHotelRoomType"),
      },
      {
        path: ':id/fee-rules',
        name: ROUTES.ADMIN.HOTEL_FEE_RULE_LIST,
        meta: {
          breadcrumb: ['{hotel}', i18n.tc('breadcrumbs.Hotel fee rule')],
          activeKey: 'admin.hotel.list'
        },
        component: routerView,
        children: [
          {
            path: '',
            name: ROUTES.ADMIN.HOTEL_FEE_RULE_LIST,
            meta: {
              activeKey: 'admin.hotel.list'
            },
            component: () => import("@components/admin/hotels/fee_rules/ListHotelFeeRule"),
          },
          {
            path: 'create',
            name: ROUTES.ADMIN.HOTEL_FEE_RULE_CREATE,
            meta: {
              breadcrumb: [i18n.tc('breadcrumbs.Hotel fee rule create')],
              activeKey: 'admin.hotel.list'
            },
            component: () => import("@components/admin/hotels/fee_rules/HotelFeeRuleCreate"),
          },
          {
            path: ':fee_rule_id/view',
            name: ROUTES.ADMIN.HOTEL_FEE_RULE_EDIT,
            meta: {
              breadcrumb: [i18n.tc('breadcrumbs.Hotel fee rule update')],
              activeKey: 'admin.hotel.list'
            },
            component: () => import( "@components/admin/hotels/fee_rules/HotelFeeRuleView"),
          },
          {
            path: ':fee_rule_id/copy',
            name: ROUTES.ADMIN.HOTEL_FEE_RULE_COPY,
            meta: {
              breadcrumb: [i18n.tc('breadcrumbs.Hotel fee rule copy')],
              activeKey: 'admin.hotel.list'
            },
            component: () => import( "@components/admin/hotels/fee_rules/HotelFeeRuleCopy"),
          },
        ]
      },
    ]
  },
];
