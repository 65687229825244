import CSAccess from "@services/middlewares/CSAccess";
import DashboardRoute from "./DashboardRoute";
import ROUTES from "@consts/routes";
import TaxiRoute from "@routes/cs/TaxiRoute";
import CSAuthAxios from "@services/middlewares/CSAuthAxios";
import AuthRoute from "@routes/cs/AuthRoute";
import ProfileRoute from "@routes/cs/ProfileRoute";
import i18n from "@/lang/i18n";
import BookingRoute from "@routes/cs/BookingRoute";
import AnalysisRoute from "@routes/cs/AnalysisRoute";

export default [
  ...AuthRoute,
  {
    path: '/cs',
    meta: {
      middlewares: [CSAuthAxios, CSAccess],
      breadcrumb: i18n.tc('breadcrumbs.Home'),
      activeKey: 'cs.dashboard'
    },

    name: ROUTES.CS.HOME,
    component: () => import('@components/_partials/Layout'),
    children: [
      {
        path: '',
        redirect: {name: ROUTES.CS.DASHBOARD}
      },
      ...DashboardRoute,
      ...TaxiRoute,
      ...ProfileRoute,
      ...BookingRoute,
      ...AnalysisRoute,
    ],
  }
]
