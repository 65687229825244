export default {
  "Home": "ダッシュボード",
  "Dashboard": "ダッシュボード",
  "Users management": "Users",
  "Create user": "Create user",
  "Hotels": "ホテル一覧画面",
  "Hotel users": "ホテルアカウント管理",
  "Taxi companies": "タクシー一覧画面",
  "Taxi users": "タクシーアカウント管理",
  "Admin users": "Adminアカウント管理",
  "Profile": "プロファイル",
  "Taxi branchs": "事業所管理",
  "Taxi types": "タクシー車種管理",
  "Master setting": "マスター設定",
  "Start point": "出発地設定",
  "Fee child rule": "子供料金ルール設定",
  "Hotel room type": "部屋タイプ管理",
  "Tourist option": "観光オプション料金管理",
  "Taxi fee rules": "タクシー料金管理",
  "Hotel fee rule": "宿泊料金管理",
  "Analysis": "集計",
  "Booking": "予約管理",
  "Available taxi": "タクシー在庫",
  "Hotel info": "ホテル情報",
  "Taxi info": "タクシー会社情報",
  "Booking create": "予約登録",
  "Hotel fee rule create": "宿泊料金の登録",
  "Hotel fee rule update": "宿泊料金の詳細",
  "Hotel fee rule copy": "宿泊料金の登録",
  "Booking edit": "予約登録",
  "Booking history": '予約履歴',
  "CS users": "予約センターアカウント管理",
  "Area": "宿泊エリア設定"
}
