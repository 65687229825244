export default {
  ROOT: 'ROOT',
  ADMIN: {
    AUTH: {
      LOGIN: 'ADMIN.LOGIN',
      LOGOUT: 'ADMIN.LOGOUT',
      FORGOT_PASSWORD: 'ADMIN.FORGOT_PASSWORD',
      RESET_PASSWORD: 'ADMIN.RESET_PASSWORD',
    },
    PROFILE: 'ADMIN.PROFILE',
    DASHBOARD: 'ADMIN.DASHBOARD',
    USERS_HOTEL_LIST: 'ADMIN.USERS_HOTEL_LIST',
    USERS_TAXI_LIST: 'ADMIN.USERS_TAXI_LIST',
    USERS_ADMIN_LIST: 'ADMIN.USERS_ADMIN_LIST',
    USERS_CS_LIST: 'ADMIN.USERS_CS_LIST',
    HOTEL_LIST: 'ADMIN.HOTEL_LIST',
    TAXI_LIST: 'ADMIN.TAXI_LIST',
    TAXI_BRANCH_LIST: 'ADMIN.TAXI_BRANCH_LIST',
    TAXI_TYPE_LIST: 'ADMIN.TAXI_TYPE_LIST',
    TOURIST_OPTION: 'ADMIN.TOURIST_OPTION',
    TAXI_FEE_RULE: 'ADMIN.TAXI_FEE_RULE',
    HOTEL_ROOM_TYPE_LIST: 'ADMIN.HOTEL_ROOM_TYPE_LIST',
    HOTEL_FEE_RULE_LIST: 'ADMIN.HOTEL_FEE_RULE_LIST',
    HOTEL_FEE_RULE_CREATE: 'ADMIN.HOTEL_FEE_RULE_CREATE',
    HOTEL_FEE_RULE_EDIT: 'ADMIN.HOTEL_FEE_RULE_EDIT',
    HOTEL_FEE_RULE_COPY: 'ADMIN.HOTEL_FEE_RULE_COPY',
    START_POINT_LIST: 'ADMIN.START_POINT_LIST',
    FEE_CHILD_RULE: 'ADMIN.FEE_CHILD_RULE',
    AREA: 'ADMIN.AREA',
    BOOKING_LIST: 'ADMIN.BOOKING_LIST',
    BOOKING_CREATE: 'ADMIN.BOOKING_CREATE',
    BOOKING_COPY_CREATE: 'ADMIN.BOOKING_COPY_CREATE',
    BOOKING_HISTORY_LIST: 'ADMIN.BOOKING_HISTORY_LIST',
    BOOKING_EDIT: 'ADMIN.BOOKING_EDIT',
    AVAILABLE_TAXI: 'ADMIN.AVAILABLE_TAXI',
    ANALYSIS: 'ADMIN.ANALYSIS',
    HOME: 'ADMIN.HOME'
  },
  CS: {
    AUTH: {
      LOGIN: 'CS.LOGIN',
      LOGOUT: 'CS.LOGOUT',
      FORGOT_PASSWORD: 'CS.FORGOT_PASSWORD',
      RESET_PASSWORD: 'CS.RESET_PASSWORD',
    },
    PROFILE: 'CS.PROFILE',
    DASHBOARD: 'CS.DASHBOARD',
    BOOKING_LIST: 'CS.BOOKING_LIST',
    BOOKING_CREATE: 'CS.BOOKING_CREATE',
    BOOKING_COPY_CREATE: 'CS.BOOKING_COPY_CREATE',
    BOOKING_HISTORY_LIST: 'CS.BOOKING_HISTORY_LIST',
    BOOKING_EDIT: 'CS.BOOKING_EDIT',
    AVAILABLE_TAXI: 'CS.AVAILABLE_TAXI',
    ANALYSIS: 'CS.ANALYSIS',
    HOME: 'CS.HOME'
  },
  HOTEL: {
    PROFILE: 'HOTEL.PROFILE',
    AUTH: {
      LOGIN: 'HOTEL.LOGIN',
      LOGOUT: 'HOTEL.LOGOUT',
      FORGOT_PASSWORD: 'HOTEL.FORGOT_PASSWORD',
      RESET_PASSWORD: 'HOTEL.RESET_PASSWORD',
    },
    DASHBOARD: 'HOTEL.DASHBOARD',
    HOME: 'HOTEL.HOME',
    INFO: 'HOTEL.INFO',
    HOTEL_ROOM_TYPE_LIST: 'HOTEL.HOTEL_ROOM_TYPE_LIST',
    HOTEL_FEE_RULE_LIST: 'HOTEL.HOTEL_FEE_RULE_LIST',
    HOTEL_FEE_RULE_CREATE: 'HOTEL.HOTEL_FEE_RULE_CREATE',
    HOTEL_FEE_RULE_EDIT: 'HOTEL.HOTEL_FEE_RULE_EDIT',
    HOTEL_FEE_RULE_COPY: 'HOTEL.HOTEL_FEE_RULE_COPY',
    BOOKING_LIST: 'HOTEL.BOOKING_LIST',
    BOOKING_EDIT: 'HOTEL.BOOKING_EDIT',
    BOOKING_HISTORY_LIST: 'HOTEL.BOOKING_HISTORY_LIST'
  },
  TAXI: {
    PROFILE: 'TAXI.PROFILE',
    AUTH: {
      LOGIN: 'TAXI.LOGIN',
      LOGOUT: 'TAXI.LOGOUT',
      FORGOT_PASSWORD: 'TAXI.FORGOT_PASSWORD',
      RESET_PASSWORD: 'TAXI.RESET_PASSWORD',
    },
    INFO: 'TAXI.INFO',
    TAXI_BRANCH_LIST: 'TAXI.TAXI_BRANCH_LIST',
    TAXI_TYPE_LIST: 'TAXI.TAXI_TYPE_LIST',
    TOURIST_OPTION: 'TAXI.TOURIST_OPTION',
    TAXI_FEE_RULE: 'TAXI.TAXI_FEE_RULE',
    DASHBOARD: 'TAXI.DASHBOARD',
    HOME: 'TAXI.HOME',
    BOOKING_LIST: 'TAXI.BOOKING_LIST',
    BOOKING_EDIT: 'TAXI.BOOKING_EDIT',
    AVAILABLE_TAXI: 'TAXI.AVAILABLE_TAXI',
    BOOKING_HISTORY_LIST: 'TAXI.BOOKING_HISTORY_LIST'
  },
}
