import ROUTES from '@consts/routes';
import ROLES from '@consts/role';
import API from '@consts/api';
import Vue from "vue";

export default function ({next}) {
  console.log('____TaxiAuthenticated____')
  let app = new Vue();
  let user = app.$auth.user('taxiAuth');
  let expireTime = app.$store.state.taxiAuth.expire_time;
  if (user && user.role === ROLES.ROLE_TAXI_NAME) {
    if (expireTime <= (new Date()).getTime()) {
      return Vue.prototype.$request.get(API.PROFILE.USER).then(() => {
        next()
      }).catch(() => {
      })
    } else {
      return next()
    }
  } else {
    Vue.router.pushByName(ROUTES.TAXI.AUTH.LOGIN)
  }
}
