import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'users/admin',
    name: ROUTES.ADMIN.USERS_ADMIN_LIST,
    meta: {breadcrumb: i18n.tc('breadcrumbs.Admin users'), activeKey: 'admin.users.admin_user'},
    component: () => import("@components/admin/users/ListAdminAccount"),
  },
  {
    path: 'users/cs',
    name: ROUTES.ADMIN.USERS_CS_LIST,
    meta: {breadcrumb: i18n.tc('breadcrumbs.CS users'), activeKey: 'admin.users.cs_user'},
    component: () => import("@components/admin/users/ListCSAccount"),
  },
  {
    path: 'users/hotel',
    name: ROUTES.ADMIN.USERS_HOTEL_LIST,
    meta: {breadcrumb: i18n.tc('breadcrumbs.Hotel users'), activeKey: 'admin.users.hotel_user'},
    component: () => import("@components/admin/hotels/ListHotelAccount"),
  },
  {
    path: 'users/taxi',
    name: ROUTES.ADMIN.USERS_TAXI_LIST,
    meta: {breadcrumb: i18n.tc('breadcrumbs.Taxi users'), activeKey: 'admin.users.taxi_user'},
    component: () => import("@components/admin/taxis/taxi_company/ListTaxiAccount"),
  }
];
