import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'dashboard',
    name: ROUTES.HOTEL.DASHBOARD,
    component: () => import("@components/hotel/dashboard/Dashboard"),
    meta: {breadcrumb: i18n.tc('breadcrumbs.Home'), activeKey: 'hotel.dashboard'},
  }
];
