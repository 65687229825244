import ROUTES from "@consts/routes";

export default [
  {
    path: 'dashboard',
    name: ROUTES.TAXI.DASHBOARD,
    component: () => import("@components/taxi/dashboard/Dashboard"),
    meta: {activeKey: 'taxi.dashboard'},
  }
];
