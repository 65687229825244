import ROUTES from '@consts/routes';
import TaxiAuthAxios from "@services/middlewares/TaxiAuthAxios";

export default [
  {
    path: '/taxi/auth',
    meta: {
      middlewares: [TaxiAuthAxios],
    },
    component: () => import('@components/base_auths/LayoutAuth'),
    children: [
      {
        path: 'login',
        name: ROUTES.TAXI.AUTH.LOGIN,
        component: () => import("@components/base_auths/Login"),
      },
      {
        path: 'forgot-password',
        name: ROUTES.TAXI.AUTH.FORGOT_PASSWORD,
        component: () => import("@components/base_auths/ForgotPassword"),
      },
      {
        path: 'reset-password',
        name: ROUTES.TAXI.AUTH.RESET_PASSWORD,
        component: () => import("@components/base_auths/ResetPassword"),
      },
    ],
  }
]
