import ROUTES from '@consts/routes';
import HotelAuthAxios from "@services/middlewares/HotelAuthAxios";

export default [
  {
    path: '/hotel/auth',
    meta: {
      middlewares: [HotelAuthAxios],
    },
    component: () => import('@components/base_auths/LayoutAuth'),
    children: [
      {
        path: 'login',
        name: ROUTES.HOTEL.AUTH.LOGIN,
        component: () => import("@components/base_auths/Login"),
      },
      {
        path: 'forgot-password',
        name: ROUTES.HOTEL.AUTH.FORGOT_PASSWORD,
        component: () => import("@components/base_auths/ForgotPassword"),
      },
      {
        path: 'reset-password',
        name: ROUTES.HOTEL.AUTH.RESET_PASSWORD,
        component: () => import("@components/base_auths/ResetPassword"),
      },
    ],
  }
]
