const STATUS_NEW = 1;
const STATUS_UPDATE = 2;
const STATUS_ARRANGER = 3;
const STATUS_CANCEL_NO_CHARGE = 4;
const STATUS_CANCEL_CHARGE = 5;
const STATUS_OPERATED = 6;
const STATUS_DRAFT = 7;
const STATUS_NEW_LABEL = '新規';
const STATUS_UPDATE_LABEL = '変更';
const STATUS_ARRANGER_LABEL = '手配済';
const STATUS_CANCEL_NO_CHARGE_LABEL = 'キャンセル（ノーチャージ）';
const STATUS_CANCEL_CHARGE_LABEL = 'キャンセル（チャージ）';
const STATUS_OPERATED_LABEL = '運行済';
const STATUS_DRAFT_LABEL = '一時保存';

export default {
  GENDERS: [
    {id: 1, name: '男性'},
    {id: 2, name: '女性'},
    {id: 3, name: '内訳不明'},
  ],
  PAYMENTS: [
    {id: 1, name: '振替（全額・一部）'},
    {id: 2, name: 'プランパッケージに含まれる'},
  ],
  LANGS: [
    {id: 1, name: '英語'},
    {id: 2, name: '簡体語'},
    {id: 3, name: '繁体語'},
    {id: 4, name: '韓国語'},
  ],
  TAXI_PLANS: [
    {id: 1, name: 'スタンダード'},
    {id: 2, name: 'プレミアム'},
    {id: 3, name: '連泊'},
    {id: 4, name: 'ペット'},
  ],
  STATUS_SELECT: [
    {id: STATUS_DRAFT, name: '一時保存'},
    {id: STATUS_NEW, name: '新規'},
    {id: STATUS_ARRANGER, name: '手配済'},
    {id: STATUS_UPDATE, name: '変更'},
    {id: STATUS_CANCEL_NO_CHARGE, name: 'キャンセル（ノーチャージ）'},
    {id: STATUS_CANCEL_CHARGE, name: 'キャンセル（チャージ）'},
    {id: STATUS_OPERATED, name: '運行済'},
  ],
  STATUS_SELECT_UPDATE: [
    {id: STATUS_NEW, name: '新規'},
    {id: STATUS_ARRANGER, name: '手配済'},
    {id: STATUS_UPDATE, name: '変更'},
    {id: STATUS_CANCEL_NO_CHARGE, name: 'キャンセル（ノーチャージ）'},
    {id: STATUS_CANCEL_CHARGE, name: 'キャンセル（チャージ）'},
    {id: STATUS_OPERATED, name: '運行済'},
  ],
  STATUS_SELECT_TAXI: [
    {id: STATUS_ARRANGER, name: '手配済'},
    {id: STATUS_UPDATE, name: '変更'},
    {id: STATUS_OPERATED, name: '運行済'},
  ],
  FORM_EXPORT: [
    {id: 1, name: '申込書 (入力用)'},
    // {id: 2, name: '確認書'},
    {id: 3, name: 'ドライバー申請書'},
    {id: 4, name: '請求書(電話予約）'},
  ],
  STATUS_NEW, STATUS_UPDATE, STATUS_ARRANGER,
  STATUS_CANCEL_NO_CHARGE, STATUS_CANCEL_CHARGE, STATUS_OPERATED,
  STATUS_NEW_LABEL,
  STATUS_UPDATE_LABEL,
  STATUS_ARRANGER_LABEL,
  STATUS_CANCEL_NO_CHARGE_LABEL,
  STATUS_CANCEL_CHARGE_LABEL,
  STATUS_OPERATED_LABEL,
  STATUS_DRAFT,STATUS_DRAFT_LABEL
}
