export default {
  'baseUrl': 'https://kyo.tfosd.com',
  API_URL: 'https://api-kyo.tfosd.com/api',
  ADMIN_API_URL: 'https://api-kyo.tfosd.com/api/admin/',
  HOTEL_API_URL: 'https://api-kyo.tfosd.com/api/hotel/',
  TAXI_API_URL: 'https://api-kyo.tfosd.com/api/taxi/',
  CS_API_URL: 'https://api-kyo.tfosd.com/api/cs/',
  APP_NAME: 'Taxi Inventory',
  APP_VERSION_URL: 'https://kyo.tfosd.com/app-version.txt'
}
