import HotelAccess from "@services/middlewares/HotelAccess";
import DashboardRoute from "./DashboardRoute";
import ROUTES from "@consts/routes";
import HotelAuthAxios from "@services/middlewares/HotelAuthAxios";
import AuthRoute from "@routes/hotel/AuthRoute";
import ProfileRoute from "@routes/hotel/ProfileRoute";
import i18n from "@/lang/i18n";
import BookingRoute from "@routes/hotel/BookingRoute";

const routerView = {
  render(c) {
    return c("router-view");
  }
}
export default [
  ...AuthRoute,
  {
    path: '/hotel',
    meta: {
      middlewares: [HotelAuthAxios, HotelAccess],
      breadcrumb: i18n.tc('breadcrumbs.Home'),
      activeKey: 'hotel.dashboard'
    },
    name: ROUTES.HOTEL.DASHBOARD,
    component: () => import("@components/_partials/Layout"),
    children: [
      {
        path: '',
        redirect: {name: ROUTES.HOTEL.DASHBOARD}
      },
      ...DashboardRoute,
      ...BookingRoute,
      ...ProfileRoute,
      {
        path: 'room_types',
        name: ROUTES.HOTEL.HOTEL_ROOM_TYPE_LIST,
        meta: {
          breadcrumb: ['{hotel}', i18n.tc('breadcrumbs.Hotel room type')],
          activeKey: 'hotel.room-types.list'
        },
        component: () => import("@components/hotel/room_types/ListHotelRoomType")
      },
      {
        path: 'fee-rules',
        name: ROUTES.HOTEL.HOTEL_FEE_RULE_LIST,
        meta: {
          breadcrumb: ['{hotel}', i18n.tc('breadcrumbs.Hotel fee rule')],
          activeKey: 'hotel.fee-rules.list'
        },
        component: routerView,
        children: [
          {
            path: '',
            name: ROUTES.HOTEL.HOTEL_FEE_RULE_LIST,
            meta: {
              activeKey: 'hotel.fee-rules.list'
            },
            component: () => import("@components/hotel/fee_rules/ListHotelFeeRule")
          },
          {
            path: 'create',
            name: ROUTES.HOTEL.HOTEL_FEE_RULE_CREATE,
            meta: {
              breadcrumb: [i18n.tc('breadcrumbs.Hotel fee rule create')],
              activeKey: 'hotel.fee-rules.list'
            },
            component: () => import("@components/hotel/fee_rules/HotelFeeRuleCreate")
          },
          {
            path: ':fee_rule_id/view',
            name: ROUTES.HOTEL.HOTEL_FEE_RULE_EDIT,
            meta: {
              breadcrumb: [i18n.tc('breadcrumbs.Hotel fee rule update')],
              activeKey: 'hotel.fee-rules.list'
            },
            component: () => import("@components/hotel/fee_rules/HotelFeeRuleView")
          },
          {
            path: ':fee_rule_id/copy',
            name: ROUTES.HOTEL.HOTEL_FEE_RULE_COPY,
            meta: {
              breadcrumb: [i18n.tc('breadcrumbs.Hotel fee rule copy')],
              activeKey: 'hotel.fee-rules.list'
            },
            component: () => import("@components/hotel/fee_rules/HotelFeeRuleCopy")
          },
        ]
      },
      {
        path: 'hotel-info',
        name: ROUTES.HOTEL.INFO,
        meta: {
          breadcrumb: i18n.tc('breadcrumbs.Hotel info'),
          activeKey: 'hotel.info'
        },
        component: () => import("@components/hotel/info/HotelInfo")
      },
    ],
  }
]
