import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'profile',
    name: ROUTES.ADMIN.PROFILE,
    meta: {breadcrumb: i18n.tc('breadcrumbs.Profile'), activeKey: 'admin.profile'},
    component: () => import("@components/base_auths/Profile"),
  },
];
