import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'analysis',
    name: ROUTES.ADMIN.ANALYSIS,
    meta: {breadcrumb: i18n.tc('breadcrumbs.Analysis'), activeKey: 'admin.analysis'},
    component: () => import("@components/admin/analysis/Analysis"),
  },
];
