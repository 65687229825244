import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

const routerView = {
  render(c) {
    return c("router-view");
  }
}

export default [{
  path: 'settings',
  component: routerView,
  children: [
    {
      path: 'start-points',
      name: ROUTES.ADMIN.START_POINT_LIST,
      meta: {
        breadcrumb: [i18n.tc('breadcrumbs.Master setting'), i18n.tc('breadcrumbs.Start point')],
        activeKey: 'admin.settings.start_point'
      },
      component: () => import("@components/admin/settings/ListStartPoint"),
    },
    {
      path: 'fee-child-rules',
      name: ROUTES.ADMIN.FEE_CHILD_RULE,
      meta: {
        breadcrumb: [i18n.tc('breadcrumbs.Master setting'), i18n.tc('breadcrumbs.Fee child rule')],
        activeKey: 'admin.settings.fee_child_rule'
      },
      component: () => import("@components/admin/settings/ListFeeChildRule"),
    },
    {
      path: 'areas',
      name: ROUTES.ADMIN.AREA,
      meta: {
        breadcrumb: [i18n.tc('breadcrumbs.Master setting'), i18n.tc('breadcrumbs.Area')],
        activeKey: 'admin.settings.area'
      },
      component: () => import("@components/admin/settings/ListArea"),
    },
  ]
}]
