import axios from 'axios'
import API from '@consts/api';
import CONFIG from '@config';
import ROUTES from '@consts/routes';
import Vue from 'vue';

export default function ({next, authKey}) {
  console.log(`____Axios Interceptors [${authKey}]____`)
  let BASE_API_URL = CONFIG.API_URL
  var LOGIN_NAME = null
  switch (authKey) {
    case 'adminAuth': {
      BASE_API_URL = CONFIG.ADMIN_API_URL;
      LOGIN_NAME = ROUTES.ADMIN.AUTH.LOGIN
      break;
    }
    case 'csAuth': {
      BASE_API_URL = CONFIG.CS_API_URL;
      LOGIN_NAME = ROUTES.CS.AUTH.LOGIN
      break;
    }
    case 'hotelAuth': {
      BASE_API_URL = CONFIG.HOTEL_API_URL;
      LOGIN_NAME = ROUTES.HOTEL.AUTH.LOGIN
      break;
    }
    case 'taxiAuth': {
      BASE_API_URL = CONFIG.TAXI_API_URL;
      LOGIN_NAME = ROUTES.TAXI.AUTH.LOGIN
      break;
    }
  }
  const app = new Vue;
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    })

    failedQueue = [];
  }

  axios.interceptors.request.use(function (config) {
    let appToken = app.$store.state[authKey].token;
    if (!config.headers.Authorization && appToken) {
      config.headers['Authorization'] = 'Bearer ' + appToken;
    }
    config.baseURL = BASE_API_URL;
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const originalRequest = error.config;
    if ((error.response.status === API.UNAUTHORIZED_CODE ||
      error.response.status === API.FORBIDDEN_CODE) &&
      !originalRequest._retry) {
      if (error.response.data?.status === 'TOKEN_EXPIRED') {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({resolve, reject})
          }).then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          }).catch(err => {
            return Promise.reject(err);
          })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          var loader = app.$loading.appShow()
          axios({
            url: API.AUTH.REFRESH,
            method: API.METHOD_POST,
            baseURL: BASE_API_URL,
          }).then(response => {
            const token = response.headers.authorization;
            const expireTime = response.headers['token-expire-time'];
            app.$store.commit(`${authKey}/updateToken`, {
              token, expire_time: expireTime
            })
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            processQueue(null, token);
            loader?.hide()
            isRefreshing = false
            resolve(axios(originalRequest));
          }).catch((err) => {
            loader?.hide()
            isRefreshing = false
            processQueue(err, null);
            reject(err);
            app.$store.commit(`${authKey}/removeToken`)
            app.$store.commit(`${authKey}/removeUser`)
            Vue.router.pushByName(LOGIN_NAME)
          })
        })
      } else {
        app.$store.commit(`${authKey}/removeToken`)
        app.$store.commit(`${authKey}/removeUser`)
        return new Promise(function (resolve, reject) {
          loader?.hide()
          Vue.router.pushByName(LOGIN_NAME)
        }).then(() => {
          Promise.reject(error)
        })
      }
    }
    return Promise.reject(error);
  });
  app.$axios = axios;
  return next();
}
