import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'available-taxi',
    name: ROUTES.CS.AVAILABLE_TAXI,
    meta: {
      breadcrumb: i18n.tc('breadcrumbs.Available taxi'),
      activeKey: 'cs.taxi.available'
    },
    component: () => import("@components/cs/taxis/available_taxi/AvailableTaxi"),
  },
];
