import {extend, localize} from "vee-validate";
import {
  between,
  confirmed,
  digits,
  email,
  integer,
  length,
  is_not,
  is,
  max,
  max_value,
  mimes,
  min,
  min_value,
  excluded,
  numeric,
  regex,
  required,
  required_if,
  size,
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import ja from "vee-validate/dist/locale/ja.json";

// Install rules
extend("required", required);
extend("required_noname", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("digits", digits);
extend("integer", integer);
extend("between", between);
extend("length", length);
extend("confirmed", confirmed);
extend("min_value", min_value);
extend("max_value", max_value);
extend("regex", regex);
extend("required_if", required_if);
extend('numeric', {
  validate(value, args) {
    return (/^[0-9０-９]+$/).test(value);
  }
});
extend('email', {
  validate(value) {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,40}$/.test(value)
  }
})
extend('password', {
  validate(value) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/.test(value)
  }
})

ja.names = {
  name: '名前',
  email: 'メールアドレス',
  phone: "電話番号",
  password: 'パスワード',
  password_confirm: 'パスワード（確認）',
  role: "ロール",
  address: "住所",
  fax: "FAX",
  taxi_company_id: "タクシー会社名",
  hotel_id: "ホテル名",
  new_password: "新しいパスワード",
  new_password_confirm: "新しいパスワード（確認）",
  current_password: "現在のパスワード",
  current_email: "現在のメールアドレス",
  new_email: "新メールアドレス",
  start_date: "開始日",
  end_date: "終了日",
  child_rule_rate_1: "小学生(大人食)料金",
  child_rule_rate_2: "小学生(子供食)料金",
  child_rule_rate_3: "未就学児（3～5歳）料金",
  child_rule_rate_4: "乳児（0～2歳）料金",
  TO_name: "コース名",
  TO_plan: "プラン",
  TO_price: "料金",
  TO_people: "人数",
  TO_time: "時間",
  taxi_type: "車種",
  start_point: "出発地",
  end_point: "送り先",
  login_email: "このメールアドレス",
  "price": "料金",
  "hotel_room_type": "部屋タイプ",
  "hotel_people_fee_rate": "1室の人数",
  "start point": "出発地",
  "hotel_name": "ホテル名",
  "room type": "部屋タイプ",
  "taxi name": "タクシー会社名",
  "branch name": "事業所名",
  "taxi type": "車種名",
  "checkin_date": "チェックイン日",
  "checkout_date": "チェックアウト日",
  "checkin_taxi_date": "往路日",
  "checkout_taxi_date": "復路日",
};

ja.messages = {
  ...ja.messages,
  "alpha": "{_field_}はアルファベットのみ使用できます。",
  "alpha_num": "{_field_}は英数字のみ使用できます。",
  "alpha_dash": "{_field_}は英数字とハイフン、アンダースコアのみ使用できます。",
  "alpha_spaces": "{_field_}はアルファベットと空白のみ使用できます。",
  "between": "{_field_}は{min}から{max}の間でなければなりません。",
  "confirmed": "{_field_}が一致しません。",
  "digits": "{_field_}は{length}桁の数字でなければなりません。",
  "dimensions": "{_field_}は幅{width}px、高さ{height}px以内でなければなりません。",
  "email": "このメールアドレスは有効なメールアドレスではありません。",
  "excluded": "{_field_}は不正な値です。",
  "ext": "{_field_}は有効なファイル形式ではありません。",
  "image": "{_field_}は有効な画像形式ではありません。",
  "integer": "{_field_}は整数のみ使用できます。",
  "is": "{_field_}が一致しません。",
  "is_not": "{_field_}は無効です。",
  "length": "半角数字{length}桁の形式で入力してください。",
  "max_value": "こちらに{max}以下の数字を入力してください。",
  "max": "{_field_}は{length}文字以内にしてください。",
  "mimes": "{_field_}は有効なファイル形式ではありません。",
  "min_value": "台数は{min}以上の数値で入力してください。",
  "min": "{_field_}は{length}文字以上でなければなりません。",
  "numeric": "{_field_}は数字のみ使用できます。",
  "oneOf": "{_field_}は有効な値ではありません。",
  "regex": "{_field_}のフォーマットが正しくありません。",
  "required": "こちらは必須項目です。",
  "required_if": "こちらは必須項目です。",
  "size": "{_field_}は{size}KB以内でなければなりません。",
  "double": "{_field_}フィールドは有効な10進数である必要があります。",
  required_noname: 'こちらは必須項目です。',
  password: 'パスワードは8文字以上~20文字以下半角英数字で、大文字、小文字、数字を全て含んでいる必要があります。',
}
extend("after_date", {
  params: ['target'],
  validate(value, {target}) {
    if (value && target) {
      return moment(value).diff(moment(target)) > 0;
    }
    return true;
  },
  message(field, obj) {
    return ja.names[field] + 'には' + ja.names[obj.target] + 'より後の日付を指定してください。';
  }
});
extend("after_eq_date", {
  params: ['target'],
  validate(value, {target}) {
    if (value && target) {
      return moment(value).diff(moment(target)) >= 0;
    }
    return true;
  },
  message(field, obj) {
    return ja.names[field] + 'は' + ja.names[obj.target] + '以降の日付を指定してください。';
  }
});

extend('taxi_type_booking', {
  params: ['target'],
  validate(value, {target}) {
    return !target;
  },
  message: '選択した車種の在庫数がなくなりました。'
})

extend('start_point_booking', {
  params: ['target'],
  validate(value, {target}) {
    return !target;
  },
  message: '選択した出発地は連携するタクシー会社が見つかりませんでした。'
})

extend('booking_code', {
  params: ['target'],
  validate(value, {target}) {
    return !target;
  },
  message: 'このコードは既に使用されています。他のコードを入力してください。'
})

extend('email_unique', {
  params: ['target'],
  validate(value, {target}) {
    return !target;
  },
  message: 'このメールアドレスは既に使用されています。'
})

extend('taxi_available', {
  validate(value, target) {
    if (target && parseInt(target) > 0) {
      return value >= parseInt(target)
    } else {
      return true;
    }
  },
  message(field, obj) {
    return '在庫数には受付数より大きい数字を設定してください（' + obj[0] + '台）'
  }
})
extend('ja_postal_code', {
  params: ['target'],
  validate(value, {target}) {
    return value && value.length === 7 && !target;
  },
  message: '有効な郵便番号を入力してください。'
})

extend('phone', {
  validate(value) {
    return String(value).length === 10 || String(value).length === 11;
  },
  message: '電話番号は10桁または11桁の半角数字で入力してください。'
})

extend('phone_mobile', {
  validate(value) {
    return String(value).length === 11;
  },
  message: '携帯番号は半角数字11桁の形式で入力してください。'
})

extend('phone_home', {
  validate(value) {
    return String(value).length === 10;
  },
  message: '自宅番号は半角数字10桁の形式で入力してください。'
})

extend('fax', {
  validate(value) {
    if (value && value.trim().length) {
      return String(value).length === 10;
    } else {
      return true;
    }
  },
  message: 'FAXは半角数字10桁の形式で入力してください。'
})

// Install messages
localize({
  ja,
  en
});

localize('ja');
