import API from '@consts/api';
import CONFIG from '@config';

export default {
  install(Vue, options) {
    const app = new Vue
    Vue.prototype.$request = {
      call: async function (options = {}) {
        if (!options?.noLoadingEffect) {
          var loader = app.$loading.appShow()
        }
        try {
          const response = await app.$axios(_.merge({
            baseURL: CONFIG.API_URL,
            timeout: 600000, //5Minute
            method: API.METHOD_GET,
            headers: {
              LOCALE: app.$cookies.get("lang") ? app.$cookies.get("lang") : 'ja',
            },
          }, options))
          if (!options?.noLoadingEffect) {
            loader?.hide()
          }
          return {
            data: response.data,
            status: response.data?.status,
            headers: response.headers,
            hasErrors: () => response.data?.status === 'error'
          }
        } catch (error) {
          if (!options?.noLoadingEffect) {
            loader?.hide()
          }
          if (options?.handleNotFound) {
            return Vue.router.pushByName('not-found')
          }
          return {
            data: error.response?.data,
            statusCode: error.response?.status,
            headers: error.response?.headers,
            hasErrors: () => true
          }
        }
      },

      get: async function (url, params, options = {}) {
        return this.call(_.merge({method: API.METHOD_GET, url, params}, options))
      },

      post: async function (url, data, options = {}) {
        return this.call(_.merge({method: API.METHOD_POST, url, data}, options))
      },

      patch: async function (url, data, options = {}) {
        return this.call(_.merge({method: API.METHOD_UPDATE, url, data}, options))
      },

      delete: async function (url, data, options = {}) {
        return this.call(_.merge({method: API.METHOD_DELETE, url, data}, options))
      },

      getUrl: path => {
        return CONFIG.API_URL + path;
      }
    }
  }
}
