<template>
  <div :class="['modal fade', modalClass]" ref="modal" role="dialog" data-backdrop="static"
       aria-hidden="true">
    <div :class="['modal-dialog', ' modal-' + size]">
      <div class="modal-content-wrapper">
        <div class="modal-content">
          <template v-if="$slots.header">
            <slot name="header"></slot>
          </template>
          <template v-else>
            <div class="modal-header clearfix text-left">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="pg-close fs-14"></i>
              </button>
              <span class="title">{{ modalHeaderTitle }}</span>
            </div>
          </template>
          <template v-if="$slots.container">
            <slot name="container"></slot>
          </template>
          <template v-else>
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div v-if="$slots.footer" class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    modalClass: String,
    modalHeaderTitle: String,
    name: String, // unique string
    size: String, //md, lg, sm,
    onHide: Function | Promise,
  },
  data() {
    return {
      isShow: false
    }
  },
  watch: {
    isShow: function (isShow) {
      if (isShow) {
        $(this.$refs.modal).modal('show')
      } else {
        $(this.$refs.modal).modal('hide')
      }
    }
  },
  mounted() {
    if (this.isShow) {
      $(this.$refs.modal).modal('show')
    } else {
      $(this.$refs.modal).modal('hide')
    }

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.isShow = false;
      if (this.onHide) {
        this.onHide()
      }
    })

    $(this.$refs.modal).on('isShow.bs.modal', () => {
      this.isShow = true;
    })
  },
  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  },
  methods: {
    hide() {
      this.isShow = false;
    },
    show() {
      this.isShow = true;
    },
  }
}
</script>
