import Config from "@config";

export const AppLocalStorage = {
  setItem: (key, value) => {
    return localStorage.setItem(Config.APP_NAME + key, value)
  },
  getItem: (key) => {
    return localStorage.getItem(Config.APP_NAME + key)
  },
  removeItem: (key) => {
    return localStorage.removeItem(Config.APP_NAME + key)
  },
}
