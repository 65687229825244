import breadcrumbs from "./breadcrumbs";

export default {
  "common": {
    "password": "Password",
    "password confirm": "パスワード再入力",
    "email": "Email",
    "mail address": "Email address",
    "language": "Language",
    "next": "Next",
    "pre": "Previous",
    "perpage": "per page"
  },
  "nav": {
    "dashboard": "Dashboard",
    "logout": "Logout",
    "user list": "Users",
  },
  "Login": {
    "page title": "Login Page",
    "sign in": "Sign In"
  },
  "breadcrumbs": breadcrumbs
}
